/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from "lodash";

import { Wrapper, Button, ButtonContainer } from '../RegistrationWellnessInterests/styles';
import { FormContainer, TitleBlock } from '../RegistrationForm/styles';
import { SectionContainer, CustomDropDown, Container, CustomMenuItem } from './styles';

import Waiting from '../Waiting';
import FormWizardSteps from '../FormWizardSteps';

import { getGeneralHealthData, addGeneralHeathData } from '../../redux/actions';

import { generalHealthFirstColumn, generalHealthSecondColumn } from '../../../mockData';

class RegistrationGeneralHealth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: ''
    }
  }

  componentDidMount() {
    const { getGeneralHealthData, longTermGoals, history, companyId } = this.props;
    if(companyId < 0) {
      history.push('/user');
    }
    getGeneralHealthData();
    if(longTermGoals) {
      if(!longTermGoals.length) {
        history.push('/user/step3');
      }
    }
  }

  saveData = (name, value) => {
    const { addGeneralHeathData } = this.props;
    addGeneralHeathData(name, value);
    this.setState({ open: '' });
  };
  
  changeOpen = (index) => {
    if(index == this.state.open) {
      this.setState({ open: ''});
    }
    else {
      this.setState({ open: index });
    }
  }

  renderGeneralHealthDropDowns = (items, column) => items.map((item, index) => {
    const ShowTitle = 1;
    return(
      <CustomDropDown
        title={this.props[item.name] !== '' ? this.props[item.name] : item.title}
        key={item.title}
        id={`dropdown-basic-${index}`}
        onClick={() => this.changeOpen(`dropdown-basic-${column}-${index}`)}
        opened={`dropdown-basic-${column}-${index}` === this.state.open ? 1 : 0 }
        onBlur={() => this.changeOpen('')}
      >
        { ShowTitle && <CustomMenuItem disabled>{item.title}</CustomMenuItem> }
        {
          item.values.map((value, index) => (
            <CustomMenuItem
              eventKey={value + index} key={value + index}
              onSelect={() => this.saveData(item.name, value)}
              active={this.props[item.name] === value}
            >
              {value}
            </CustomMenuItem>
          ))
        }
      </CustomDropDown>
    )
  });

  saveHealthData = () => {
    const { history } = this.props;
    history.push('/user/step5');
  };

  render() {
    const { signingIn, exercise_day_per_week, smoke, average_sleep, average_water_intake, pain_experience, prescription_intake, history, physical_exam_frequency } = this.props;
    const generalHealthValues = {exercise_day_per_week, smoke, average_sleep, average_water_intake, pain_experience, prescription_intake, physical_exam_frequency};
    return(
      <Wrapper>
        {signingIn && <Waiting />}
        <FormContainer>
          <TitleBlock>
            Registration - General health <span>Step 4</span>
          </TitleBlock>
          <FormWizardSteps history={history} activeStep={4} />
          <Container>
            <SectionContainer>
              {this.renderGeneralHealthDropDowns(generalHealthFirstColumn, 'first')}
            </SectionContainer>
            <SectionContainer>
              {this.renderGeneralHealthDropDowns(generalHealthSecondColumn, 'second')}
            </SectionContainer>
          </Container>
          <ButtonContainer>
            <Button color={'#bbbbbb'} onClick={() => history.push('/user/step3')}>
              <i className="fas fa-chevron-left" />
              previous
            </Button>
            <Button 
              onClick={this.saveHealthData} 
              disabled={Object.values(_.omitBy(generalHealthValues, _.isEmpty)).length < 7}
            >
              next
              <i className="fas fa-chevron-right" />
            </Button>
          </ButtonContainer>
        </FormContainer>
      </Wrapper>
    )
  }
}

RegistrationGeneralHealth.propTypes = {
  history: PropTypes.object.isRequired,
  error: PropTypes.string,
  signingIn: PropTypes.bool.isRequired,
  signedIn: PropTypes.bool.isRequired,
  exercise_day_per_week: PropTypes.string.isRequired,
  smoke: PropTypes.string.isRequired,
  average_sleep: PropTypes.string.isRequired,
  average_water_intake: PropTypes.string.isRequired,
  pain_experience: PropTypes.string.isRequired,
  prescription_intake: PropTypes.string.isRequired,
  getGeneralHealthData: PropTypes.func.isRequired,
  addGeneralHeathData: PropTypes.func.isRequired,
  longTermGoals: PropTypes.array,
  companyId: PropTypes.number.isRequired,
  physical_exam_frequency: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  signingIn: state.register.signingIn,
  signedIn: state.register.signedIn,
  error: state.register.error,
  exercise_day_per_week: state.register.exercise_day_per_week,
  smoke: state.register.smoke,
  average_sleep: state.register.average_sleep,
  average_water_intake: state.register.average_water_intake,
  pain_experience: state.register.pain_experience,
  prescription_intake: state.register.prescription_intake,
  longTermGoals: state.register.long_term,
  physical_exam_frequency: state.register.physical_exam_frequency
});

const mapDispatchToProps = (dispatch) => ({
  getGeneralHealthData: () => dispatch(getGeneralHealthData()),
  addGeneralHeathData: (name, value) => dispatch(addGeneralHeathData(name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationGeneralHealth);
