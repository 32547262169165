import styled,{css} from 'styled-components';
import {  Button } from '../LoginPage/styles';

const RegularFont = 'Rubik-Regular';

const Link = styled.a`
  cursor: pointer;
`;

const Padding = styled.div`
  padding: 34px 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .name {
    margin-left: 10px;
    font-size: 16px;
    letter-spacing: 0px;
    color: rgb(153,153,153);
    font-family: ${RegularFont};
    text-transform: none;
  }
@media (max-width: 500px) {
  border-bottom:none;
  padding:25px 0;
  .name{
  text-align:left
  }
}

`;
const NextButton =styled(Button)`
  margin: 0px;
  width: 200px;
  height:50px;
  font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
background: #69C2FF;
border-radius: 3px;
display: flex;
align-items: center;
justify-content:center;
text-align: center;

color: #FFFFFF;
  
  > i {
    margin-left: 10px;
    font-size: 12px;
  }
  @media (max-width: 500px) {
  width: 90%;
  
    }
`;

const BackButton = styled(NextButton)`
  width: 200px;
  background-color: ${({color,disabled}) => color ? color :disabled?"#9C9C9C": '#159fc9'};
  border: ${({color,disabled}) => color ? `1px solid ${color}` :disabled?"#9C9C9C": '1px solid #159fc9'};
  float: right;
  padding: 10px;
  margin:0;
  font-size: 18px;
    color: ${({color}) => color &&  'rgba(105, 194, 255, 1)'};

  &:first-child {
    float: left;
  }
  
  > i {
    margin-right: 0;
    margin-left: 5px;
  }
  
  ${({color}) => color && css`
    > i {
      margin-left: 0;
      margin-right: 5px;
    }
  `}
  @media (max-width: 500px) {
    width: 90%;
  margin-top:0;
  background-color:unset;
  border:none;

    }
`;
const CheckMark = styled.span`
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  position: relative;
  height: 35px;
  width: 35px;
  padding:4px;
  border-radius: 50%;
  border: 1px solid #9c9c9c;
box-shadow:0px 0px 0px 3px white inset;
    background:${({ checked }) => checked ?"linear-gradient(360deg, #022C43 0%, #0D425C 60.94%, #1E6481 100%)":"unset"};
    display:flex;
justify-content:center;
align-items:center;
  &::after {
    content: "";
    position: absolute;
    display: block; 
    width: 8px;
    height: 14px;
    border-style: solid;
    border-width: 0 3px 3px 0;
    border-color: ${({ checked }) => checked ? 'white' : '#efefef'};
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top-color: transparent;
    border-left-color: transparent;
  }


  @media (max-width: 500px) {
    height: 28px;
    width: 28px;
  }

`;



const ButtonContainer = styled.div`

  width: 100%;
  background:white;
  display: flex;
  justify-content:space-between;
align-items: center;

text-align: center;
border-top:1px solid rgba(156,156,156,0.6);
padding-top:25px;
@media (max-width: 500px) {
  width: calc(100% + 32px);
  margin: 0;
  margin-left: -16px;
  justify-content:center; 
  position:unset;
  flex-direction: column-reverse;
  flex-wrap:wrap;
  padding:25px 0;
  border-top:none;
  box-shadow:unset;
  background: #f3f6f9;

  }
`;


export { Padding,Button,ButtonContainer,NextButton,BackButton,CheckMark, Link};
