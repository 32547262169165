import styled, {css} from 'styled-components';
import { DropdownButton, DropdownItem } from 'react-bootstrap';

import { SectionWrapper } from '../RegistrationWellnessInterests/styles';

const Container = styled.div`
  float: left;
  width: 100%;
  padding: 20px 0;
`;

const SectionContainer = styled(SectionWrapper)`
  padding: 0 6vw 0 3vw;
  width: 50%;
  
  &:first-child {
    padding: 0 3vw 0 6vw;  
  }
  
  @media (max-width: 767px) {
    padding: 0 3vw;
    &:first-child {
      padding: 0 3vw;  
    }
  }
  
  @media (max-width: 500px) {
    padding: 0; 
    &:first-child {
      padding: 0;  
    }
  }
  
  .dropdown .btn-group {
    width: 100%;
  }

  .btn-group {
    width: 100%;
  }
  
  .btn-group.open .dropdown-toggle, .btn-group.open .dropdown-toggle:active, .btn-group.open .dropdown-toggle:hover, .btn-group.open .dropdown-toggle:focus
    .btn-group.open .dropdown-toggle:active:focus {
    outline: none;
    background-color: #eff9fd;
    border-color: #cdcbcb;
    box-shadow: none;
    color: rgb(153,153,153);
  }
  
  ul.dropdown-menu {
    width: 100%;
    margin: 0;
    background-color: #eff9fd;
   
    > li {
      > a:hover, > a:active, a:focus {
        color: rgb(51,51,51);
        background-color: #eff9fd;
        outline: none;
      }
    }
  }
  
  .dropdown-menu>.active>a, .dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover {
    background-color: #e3f3f7;
    color: black;
  }
`;

const CustomDropDown = styled(DropdownButton)`
  width: 100%;
  height: 50px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #cdcbcb;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  font-weight: 300;
  margin-top: 20px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding: 10px 40px 10px 20px;
  
  > span {
    float: right;
    margin-top: 6px;
    border-bottom: 5px solid transparent;
    border-left: 7px solid #159fc9;
    border-top: 5px solid transparent;
    position: absolute;
    right: 20px;
  }

  ${({ company }) => company && css`
    > span {
      float: right;
      margin-top: 6px;
      border-bottom: 5px solid transparent;
      border-left: 7px solid #159fc9;
      border-top: 5px solid transparent;
      position: absolute;
      right: 10px;
    }
  `}

  ${({ opened }) => opened && css`
    > span {
      float: right;
      margin-top: 6px;
      border-top: 7px dashed #159fc9;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      position: absolute;
      right: 20px;
    }
  `}
  
  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    background-color: #eff9fd;
    border-color: #cdcbcb;
    box-shadow: none;
    color: rgb(153,153,153);
  }
`;

const CustomMenuItem = styled(DropdownItem)`
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgb(51,51,51);
  background-color: #eff9fd;
  text-transform: capitalize;
  > a {
    white-space: ${({ locationmenu }) => !locationmenu && 'normal !important'};
  }
`;

const CustomDropDownV2 = styled(DropdownButton)`
  width: 100%;
  height: 50px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #cdcbcb;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  font-weight: 300;
  margin-top: 20px;
  text-overflow: ellipsis;
  padding: 0px;
  height: 100%;
  background: white;
  .dropdown-menu{
    min-width: 100% !important;
  }
  .dropdown-item{
    text-align:center;
  }
  >button{
    width:100%;
    background:white;
    color:#005c87;
    border: 1px solid: #005c87;
    border: none;
    text-align:center;
    .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
      width:100% !important;
      background:white !important;
      color:#005c87 !important;
      border: 1px solid: #005c87 !important;
    }
    &:active, &:hover, &:focus, &:active:focus {
      width:100% !important;
      background:white !important;
      color:#005c87 !important;
      border: 1px solid: #005c87 !important;
    }
  }
  > span {
    float: right;
    margin-top: 6px;
    border-bottom: 5px solid transparent;
    border-left: 7px solid #159fc9;
    border-top: 5px solid transparent;
    position: absolute;
    right: 20px;
  }

  ${({ company }) => company && css`
    > span {
      float: right;
      margin-top: 6px;
      border-bottom: 5px solid transparent;
      border-left: 7px solid #159fc9;
      border-top: 5px solid transparent;
      position: absolute;
      right: 10px;
    }
  `}

  ${({ opened }) => opened && css`
    > span {
      float: right;
      margin-top: 6px;
      border-top: 7px dashed #159fc9;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      position: absolute;
      right: 20px;
    }
  `}
  
  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    background-color: #eff9fd;
    border-color: #cdcbcb;
    box-shadow: none;
    color: rgb(153,153,153);
  }
`;

export { SectionContainer, CustomDropDown, Container, CustomMenuItem, CustomDropDownV2 };
