import React, { Component } from "react";
import { connect } from 'react-redux';
import { signUp } from '../../redux/actions';
import { FormContainer, HeaderBox, Layout, Title } from '../RegistrationForm/styles';
import { Padding, Link, ButtonContainer, BackButton, NextButton, CheckMark } from "./style";
import FormWizardSteps from '../FormWizardSteps';
import PropTypes from 'prop-types';
import { saveUserInformation, clearGeneralHealthData, resetLocationDepartment, handleSignUpButtonStatus } from '../../redux/actions';
import { COMPANY_NAME } from '../../utils/config';
import { withTranslation } from "react-i18next";


class RegistrationLegalUpdates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonStatus: false
    }
  }
  changeCheckData = () => {
    const { acceptedPrivacyPolicy, saveUserInformation } = this.props;
    if (acceptedPrivacyPolicy) {
      saveUserInformation('acceptedPrivacyPolicy', 0);
    } else {
      saveUserInformation('acceptedPrivacyPolicy', 1);
    }
  };

  onSignUp = () => {
    const { signUp, history, handleSignUpButtonStatus } = this.props;
    const { buttonStatus } = this.state;
    handleSignUpButtonStatus(true);
    if (!buttonStatus) {
      signUp(history);
      this.setState({ buttonStatus: true });
    }
  };

  openNewTab = (url) => {
    window.open(url)
  };
  componentDidMount() {
    const { userType, clearGeneralHealthData, resetLocationDepartment } = this.props;
    if (userType === 2) {
      clearGeneralHealthData()
    }
    if (userType === 1 || userType === 2) {
      resetLocationDepartment()
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.signupButtonStatus !== this.props.signupButtonStatus) {
      if (!this.props.signupButtonStatus) {
        this.setState({ buttonStatus: false });
      }
    }
  }
  render() {
    const { history, acceptedPrivacyPolicy, userType, signupButtonStatus, t } = this.props;
    // const stepNo = (locationDetails && locationDetails.length > 0) || (departmentDetails && departmentDetails.length > 0) ? 6 : 5;
    return (<Layout>
      <FormWizardSteps history={history} activeStep={4} userType={userType} />
      <Title>{t("Legal Updates")}</Title>

      <FormContainer >

        <HeaderBox none={true}>
          <h1>{t("Legal Updates")}</h1>
          <h3>{t("Confirm the legal agreement by click below check box.")}</h3>
        </HeaderBox>
        <Padding>
          <div className="check">
            <CheckMark
              checked={acceptedPrivacyPolicy}
              onClick={() => this.changeCheckData()}
            />
          </div>
          <div className="name">
            {t("I have read and agree to")} {`${COMPANY_NAME === ' Training Amigo ' ? ' Training Amigo ' : ' Woliba'}'s `} {t("updated")}
            <Link
              onClick={() => this.openNewTab('https://woliba.io/privacy-policy')}
            >
              <span> {t("Privacy Policy")} </span>
            </Link>
            {t("and")}
            <Link
              onClick={() => this.openNewTab('https://woliba.io/terms-of-use')}
            >
              <span> {t("Terms of Use.")}</span>
            </Link>
          </div>
        </Padding>
        <ButtonContainer>
          <BackButton color={'rgba(105, 194, 255, 0.1)'} onClick={() => history.push('/user/step3')}>
            <i className="fas fa-chevron-left" />
            {t("Go Back")}
          </BackButton>
          <NextButton
            disabled={!acceptedPrivacyPolicy || signupButtonStatus}
            onClick={() => !signupButtonStatus && this.onSignUp()}

          >
            {t("Submit")}
          </NextButton>
        </ButtonContainer>
      </FormContainer>


    </Layout>
    );
  }
}

RegistrationLegalUpdates.propTypes = {
  saveUserInformation: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  signUp: PropTypes.func.isRequired,
  acceptedPrivacyPolicy: PropTypes.number.isRequired,
  userType: PropTypes.number,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
  clearGeneralHealthData: PropTypes.func,
  resetLocationDepartment: PropTypes.func,
  handleSignUpButtonStatus: PropTypes.func,
  signupButtonStatus: PropTypes.bool,
  t: PropTypes.func

};

const mapStateToProps = (state) => ({
  acceptedPrivacyPolicy: state.register.acceptedPrivacyPolicy,
  userType: state.register.user_type,
  locationDetails: state.register.locationDetails,
  departmentDetails: state.register.departmentDetails,
  signupButtonStatus: state.register.signupButtonStatus,
});

const mapDispatchToProps = (dispatch) => ({
  signUp: (history) => dispatch(signUp(history)),
  saveUserInformation: (name, value) => dispatch(saveUserInformation(name, value)),
  clearGeneralHealthData: () => dispatch(clearGeneralHealthData()),
  resetLocationDepartment: () => dispatch(resetLocationDepartment()),
  handleSignUpButtonStatus: (bool) => dispatch(handleSignUpButtonStatus(bool))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegistrationLegalUpdates));
